//pretty photo
jQuery(document).ready(function(){
        //custom scripts
        var password = document.getElementById("password")
  , confirm_password = document.getElementById("password_confirmation");

  function validatePassword(){
    if(password.value != confirm_password.value) {
      confirm_password.setCustomValidity("A két jelszó nem egyezik meg.");
    } else {
      confirm_password.setCustomValidity('');
    }
  }

  if(password && confirm_password)
  {
    password.onchange = validatePassword;
    confirm_password.onkeyup = validatePassword;
  }


  $('.equal_height').matchHeight();
  $('.profile_image').matchHeight();

  $.fn.matchHeight._afterUpdate = function(event, groups) {
    $(".imgLiquidFill").imgLiquid();
  }

  var bookmark_list_html = "";
  jQuery('.bookmark_name').each(function(){
      
      var bookmark_name = $(this).html().replace("Könyvjelző: ", "");
      
      bookmark_list_html += "<li><a href='#bookmark_" + $(this).parent().attr('data-bookmark-id') + "'>" + bookmark_name + "</a></li>";

      $('.article_bookmark_list').html(bookmark_list_html);
            
  });

  $(".sticky").stick_in_parent();

}); 